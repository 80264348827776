import React, {useEffect, useState} from 'react';
import { useNavigate, } from 'react-router-dom';
import './styles.css';
import $ from "jquery";

function AboutCompany() {
    const navigate = useNavigate();
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const email = localStorage.getItem("email");

    const recruiterPhone = localStorage.getItem("phoneNumber");
    const userId = localStorage.getItem("userId"); 
    const userLoginId = localStorage.getItem("userLoginId");
    const companyId =  localStorage.getItem("companyId"); 

    const recruiterName = firstName + " " + lastName;

    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyHeadquarters, setCompanyHeadquarters] = useState("");
    const [companyField, setCompanyField] = useState("Engineering");
    const [companyClassification, setCompanyClassification] = useState("Startup");
    const [companySize, setCompanySize] = useState(5);
    const [dateFounded, setDateFounded] = useState("");

    const [companyDescription, setCompanyDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [tikTok, setTikTok] = useState("");

    
    //end points
    const recruiterInfoEndpoint = process.env.REACT_APP_RECRUITER_ADD_ENDPOINT;

    function createOrBack(e) {
        e.preventDefault();
        navigate('/aboutRecruiter');
    }

    function createAccount() {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        });
        localStorage.setItem("companyName", companyName);
        $.post(
            `${recruiterInfoEndpoint}`,
            JSON.stringify({ userId: userId, userLoginId: userLoginId, 
                recruiterName: recruiterName, recruiterEmail: email, recruiterPhone: recruiterPhone, 
                companyId: companyId, companyName: companyName, companyEmail: companyEmail,
                companyHeadquarters: companyHeadquarters, companyField: companyField, companyClassification: companyClassification,
                companySize: companySize, dateFounded: dateFounded, active: true,
                companyDescription: companyDescription, website: website, linkedIn: linkedIn,
                instagram: instagram, facebook: facebook, tikTok: tikTok}),
            (data) => {
                navigate("/dashboard");
            }
        ).fail(data3 => {
            console.log(`[API FAILED]: ${data3}`)
        });
    }

    return (
            <div className="company-container">
                <div className="company-box">
 
                <header className="company-flex-header">
                    <div className="company-header-item">
                        <div className="company-circle1"></div>
                        <div className="company-text-header1">About You</div>
                    </div>

                    <div className="company-header-item company-line"></div>
                    
                    <div className="company-header-item">
                        <div  className="company-circle2"></div>
                        <div className="company-text-header2">About Your Company</div>
                    </div>
                </header>
                    
                    <section className="company-section">
                        <h1 className="company-h1">About Your Company</h1>
                        <form className="company-form" onSubmit={createOrBack}>
                            <div className="company-form-group">
                                <label className="company-label">Company Name</label>
                                <input className="company-input1" type="text" onChange={e => setCompanyName(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company Email</label>
                                <input className="company-input1" type="text" onChange={e => setCompanyEmail(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company Headquarters</label>
                                <input className="company-input1" type="text" placeholder="City, State, Country" onChange={e => setCompanyHeadquarters(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company Field</label>
                                <select className="company-select1" name="Company Field" id="" onChange={e => setCompanyField(e.target.value)}>
                                    <option value="engineering">Engineering</option>
                                    <option value="legal">Legal</option>
                                    <option value="computerScience">Computer Science/IT</option>
                                    <option value="business">Business</option>
                                    <option value="healthCare">Health Care</option>
                                    <option value="marketing">Marketing</option>
                                    <option value="finance">Finance</option>
                                </select>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company Classification</label>
                                <select className="company-select1" name="Company Classification" id="" onChange={e => setCompanyClassification(e.target.value)}>
                                    <option value="startup">Startup</option>
                                    <option value="midtier">Midtier</option>
                                    <option value="fortune500">Fortune 500</option>
                                </select>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company Size</label>
                                <input className="company-input1" type="text" placeholder="Exact Number" onChange={e => setCompanySize(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Date Founded</label>
                                <input className="company-input1" type="date" placeholder="MM/YYYY" onChange={e => setDateFounded(e.target.value)} inputStyle="dateForm"></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Description</label>
                                <textarea className="company-textarea1" type="text" placeholder="Short blurb on what your company does, why it was founded and maybe a fun fact!" onChange={e => setCompanyDescription(e.target.value)}></textarea>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Website</label>
                                <input className="company-input1" type="text" onChange={e => setWebsite(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Company LinkedIn</label>
                                <input className="company-input1" type="text" onChange={e => setLinkedIn(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Instagram</label>
                                <input className="company-input1" type="text" onChange={e => setInstagram(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Facebook</label>
                                <input className="company-input1" type="text" onChange={e => setFacebook(e.target.value)}></input>
                            </div>

                            <div className="company-form-group">
                                <label className="company-label">Tiktok</label>
                                <input className="company-input1" type="text" onChange={e => setTikTok(e.target.value)}></input>
                            </div>

                            <div className="company-form-button">
                                <button className="company-button1" type="submit" value="Submit" onClick={(e)=>createOrBack(e)}>Back</button>
                                <button className="company-button2" type="submit" value="Submit" onClick={createAccount}>Create Account</button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
    )
}

export default AboutCompany;
import React, { useEffect, useState } from "react";
import "./styles.css";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const InternshipInfo = ({ internshipInfo, update_refresh }) => {
  const postInternshipEdit = process.env.REACT_APP_EDIT_INTERNSHIP_ENDPOINT;
  const [studentCount, setStudentCount] = useState(null);
  const [reviewed, setReviewed] = useState(null);
  const [hired, setHired] = useState(null);
  const [declined, setDeclined] = useState(null);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        if (internshipInfo.internshipId) {
          const response = await fetch(
            `${process.env.REACT_APP_GET_STUDENTS_APPLIED_BY_ID_ENDPOINT}?internshipId=${internshipInfo.internshipId}`
          );

          if (response.ok) {
            const data = await response.json();
            setStudentCount(data.length);

            const reviewed = data.filter(
              (student) => student.status === "Reviewed"
            );
            const hired = data.filter((student) => student.status === "Hired");
            const declined = data.filter(
              (student) => student.status === "Declined"
            );

            setReviewed(reviewed.length);
            setHired(hired.length);
            setDeclined(declined.length);
          } else {
            throw new Error("Network response was not ok.");
          }
        }
      } catch (error) {
        console.error("Error fetching student count:", error);
      }
    };
    fetchStudentCount();
  }, [internshipInfo.internshipId]);

  const Text = styled(Typography)(() => ({
    fontFamily: "Montserrat, san-serif !important",
    textTransform: "capitalize",
  }));

  const {
    internshipTitle,
    internshipState,
    internshipCity,
    active,
  } = internshipInfo;
  const position_status = ["open", "paused"];

  const post_status = async (url, data) => {
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    fetch(url, request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("network error");
        }
      })
      .then((_) => {
        update_refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="internship-info-container"
      onClick={() => {
        window.location.href = `/internship/${internshipInfo.internshipId}/applicants`;
      }}
    >
      <div className="internship-info-item">
        <Text>{internshipTitle}</Text>
      </div>
      <div className="internship-info-item">
        {internshipCity !== null && internshipState !== null ? (
          <Text>
            {internshipCity}, {internshipState}
          </Text>
        ) : (
          <Text>United States</Text>
        )}
      </div>
      <div className="flex-row-container">
        <div
          className="internship-info-item"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          {studentCount > 0 ? <Text>{studentCount}</Text> : <Text> - </Text>}
          <Text style={{ fontSize: "10px" }}>Applicants</Text>
        </div>
        <div
          className="internship-info-item"
          style={{ flexDirection: "column", justifyContent: "center" }}
          id="applicant-item"
        >
          {reviewed > 0 ? <Text>{reviewed}</Text> : <Text> - </Text>}{" "}
          <Text style={{ fontSize: "10px" }} id="applicant-item">
            Reviewed
          </Text>
        </div>
        <div
          className="internship-info-item"
          style={{ flexDirection: "column", justifyContent: "center" }}
          id="applicant-item"
        >
          {hired > 0 ? <Text>{hired}</Text> : <Text> - </Text>}{" "}
          <Text style={{ fontSize: "10px" }}>Hired</Text>
        </div>
        <div
          className="internship-info-item"
          style={{ flexDirection: "column", justifyContent: "center" }}
          id="applicant-item"
        >
          {declined > 0 ? <Text>{declined}</Text> : <Text> - </Text>}{" "}
          <Text style={{ fontSize: "10px" }} id="applicant-item">
            Declined
          </Text>
        </div>
      </div>

      <div
        className="internship-info-item internship-status-container"
        style={{ flexDirection: "row", justifyContent: "center" }}
        onClick={handleDropdownClick}
      >
        <FiberManualRecordIcon
          style={{
            fontSize: "18px",
            color: active ? "green" : "yellow",
            paddingRight: "5px",
          }}
        />
        <Text>{active ? "Open" : "Paused"}</Text>
        <div
          className="internship-dropdown-status"
          onClick={() => handleDropdownClick}
        >
          {position_status.map((status, index) => {
            let status_value = "";
            switch (status) {
              case "open":
                status_value = true;
                break;
              case "paused":
                status_value = false;
                break;
            }
            if (status_value != active) {
              let color = "";
              switch (status) {
                case "open":
                  color = "green";
                  break;
                case "paused":
                  color = "yellow";
                  break;
              }
              return (
                <div
                  className="internship-drop-mini-container"
                  key={index}
                  onClick={() => {
                    let newData = internshipInfo;
                    newData.active = !internshipInfo.active;
                    post_status(postInternshipEdit, newData);
                  }}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "18px",
                      color: color,
                      paddingRight: "5px",
                      zIndex: "10",
                    }}
                  />
                  <Text>{status}</Text>
                </div>
              );
            }
          })}
        </div>
        <KeyboardArrowDownIcon style={{ marginLeft: "15px" }} />
      </div>
      <div
        className="internship-info-item"
        style={{ justifyContent: "center" }}
      >
        <MoreVertIcon />
      </div>
    </div>
  );
};

export default InternshipInfo;

import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import "./styles.css";
import $ from "jquery";
import React from 'react';
import PostInternships from "../../components/postInternship";
import InternshipInfo from "../../components/internshipInfo";
import CompanyProfileUser from "../Profiles/components/CompanyProfile/CompanyProfileUser";

function dashboard() {
  const getInternshipEndpoint = process.env.REACT_APP_GET_INTERNSHIP_ENDPOINT;
  const [internships, setInternships] = useState([]);
  const [refresh_internship,setRefresh_internship]=useState(true);
  useEffect(() => {
    const companyId = localStorage.getItem("companyId"); 
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });
    $.get(
      `${getInternshipEndpoint}?CompanyId=${companyId}`,
      (res) => {
        setInternships(res);
      }
    )
  },[]);
  const update_refresh =() =>{
    setRefresh_internship(!refresh_internship);
  }

  return (
    <div>
      <Navbar showSidebar={true} />
      <div className="dashboard-container">
        <article className="glass-container">
          <div className="flex-row-container-header"> 
          <h1 className="title">Internships </h1>
          <PostInternships src=""/>
          </div>
          <div className="dashboard-internship-title-container">
            <div className="dashboard-internship-info-title">Position</div>
            <div className="dashboard-internship-info-title">Location</div>
            <div className="dashboard-internship-info-title">Applicants</div>
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>Status</div>
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>More</div>
          </div>
          <div className="dashboard-internships-info-container">
            {internships.size  !== 0 ? internships.map((internship, index) => {
              return <InternshipInfo internshipInfo = {internship} key={index} update_refresh={update_refresh}/>
            }) : <div className="dashboard-zero-internships-message">No Internships for your account.</div>}
          </div>
          
        </article>
      </div>
    </div>
  );
}

export default dashboard;
import React, { useState, useEffect } from 'react';
import EyeOpen from "../../images/eye-open.png"
import EyeClose from "../../images/eye-close.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import Graphic from '../../images/login.svg';
import Logo from '../../images/ig-logo-black.png';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ExternalAuth from '../../components/externalAuth';
import ForgotPassModal from '../../components/recruiterLogin/ForgotPassModal';

function RecruiterLogin() {
    const registerEndpoint = process.env.REACT_APP_REGISTER_ENDPOINT;
    const loginEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [hasEightChars, setHasEightChars] = useState(false);
    const [containNums, setContainNums] = useState(false);
    const [containUpperCase, setContainUpperCase] = useState(false);
    const [containLowerCase, setContainLowerCase] = useState(false);
    const [containNonAlphaNumeric, setContainNonAlphaNumeric] = useState(false);
    const [isResetBtnDisabled, setIsResetBtnDisabled] = useState(true);

    const handlePasswordRestraintCheck = (e) => {
        if (e.target.value.length < 8) {
          setHasEightChars(false);
        } else {
          setHasEightChars(true);
        }
  
        const regexForNum = /\d/;
        const regexForUpperCase = /[A-Z]/;
        const regexForLowerCase = /[a-z]/;
        const regexForNonAlphaNumeric = /[^a-zA-Z0-9]/g;
  
        if (regexForNum.test(e.target.value)) {
          setContainNums(true);
        } else {
          setContainNums(false);
        }
  
        if (regexForUpperCase.test(e.target.value)) {
          setContainUpperCase(true);
        } else {
          setContainUpperCase(false);
        }
  
        if (regexForLowerCase.test(e.target.value)) {
          setContainLowerCase(true);
        } else {
          setContainLowerCase(false);
        }
  
        if (regexForNonAlphaNumeric.test(e.target.value)) {
          setContainNonAlphaNumeric(true);
        } else {
          setContainNonAlphaNumeric(false);
        }
    
        setPassword(e.target.value);
      }

    const navigate = useNavigate();

    const passShowHide = (e) => {
        e.preventDefault();
        if (hidden === false) {
          setHidden(true);
        } else {
          setHidden(false);
        }
      };

      const handleSetTruePassModal = (event) => {
        event.preventDefault();
        setShowPassModal(true);
      };

      const handleSetFalsePassModal = () => {
        setShowPassModal(false);
      };

    const registerFunc = () => {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        });

        $.post(
            `${registerEndpoint}`,
            JSON.stringify({ Email: email, Password: password}),
            (res) => {
              const resData = res.responseData.recruiterUser;
              localStorage.setItem("userId", resData.userId); 
              localStorage.setItem("userLoginId", resData.userLoginId);
              localStorage.setItem("companyId", resData.company.companyId); 
              $.post(
                `${loginEndpoint}`,
                JSON.stringify({ Email: email, Password: password }),
                (data) => {
                localStorage.setItem("companyId", data.companyID);
                navigate("/aboutRecruiter");
                }
              ).fail((data2) => 
              {
                console.log("[API] /aboutRecruiter fail")
              })
            }
          ).fail(data3 => {
            console.log("[API] /aboutRecruiter fail")
        })
    }

    const loginFunc = () => {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        });

        $.post(
            `${loginEndpoint}`,
            JSON.stringify({ Email: email, Password: password }),
            (data) => {
              localStorage.setItem("companyId", data.companyID);
              localStorage.setItem("companyName", data.companyName);
              localStorage.setItem("userId", data.userID);
              localStorage.setItem("token", data.token);
              navigate("/dashboard");
            }
          ).fail((data2) => {
            if (data2.status === 401) {
                console.log("[API] LOGIN fail");
            }
          });
    }

    useEffect(() => {
        if (hasEightChars && containNums && containUpperCase && containLowerCase && containNonAlphaNumeric) {
          setIsResetBtnDisabled(false)
        } else {
          setIsResetBtnDisabled(true)
        }
      }, [hasEightChars, containNums, containUpperCase, containLowerCase, containNonAlphaNumeric])

    if (isLoggedIn) {
        return (
            <div className='login'>
                <div className="nav-top">
                    <img src={Logo} alt="" onClick={()=>{navigate("/")}}/>
                </div>

                {showPassModal && <ForgotPassModal handleSetFalsePassModal={handleSetFalsePassModal} />}

                <div className='login-container'>
                    <div className="left-container">
                        <h3>Welcome to Internships Democratized</h3>
                        <div className="input-container">
                            <div className="text-container">
                                <p>I'm a: </p>
                                <input type="text" value="Recruiter" readOnly disabled/>
                            </div>
                            <input type="text" placeholder="Email" onChange={e => setEmail(e.target.value)}/>
                            <div className="text-input-signup">
                                <input
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Password"
                                    placeholder="Password"
                                    className="text-input"
                                    type={hidden === false ? "password" : "text"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                />
                                <img
                                    className="eyeicon"
                                    src={
                                        hidden === false ? EyeOpen : EyeClose
                                    }
                                    alt="Eye Icon"
                                    onClick={(e) => passShowHide(e)}
                                />
                            </div>
                            <p className="password_forgot">
                              <button
                                className="login__forgot-link"
                                onClick={handleSetTruePassModal}>Forgot Password?
                              </button>
                            </p>
                        </div>
                        
                        <div className="button" value="Submit" onClick={loginFunc}>
                            <p>Login</p>
                        </div>
                        <div className="btn-text" >
                            <p>New member?</p>
                            <span
                            onClick={() => {
                                setIsLoggedIn(false);
                            }}
                            style={{fontSize: " 1.2rem",cursor: "pointer" , color:"black", fontWeight:"500"}}
                            >
                            Sign Up
                            </span>
                        </div>

                        <p><b>Login With</b></p>
                        <ExternalAuth isLoggedIn={true} />
                    </div>
                    <img src={Graphic} alt="" className='login-img'/>
                </div>
            </div>
        )
    }
    
    return (
        <div className='signup'>
            <div className="nav-top">
                <img src={Logo} alt="" onClick={()=>{navigate("/")}}/>
            </div>

            {showPassModal && <ForgotPassModal handleSetFalsePassModal={handleSetFalsePassModal} />}

            <div className='signup-container'>
                <div className="left-container">
                    <h3>Welcome to Internships Democratized</h3>
                    <div className="input-container">
                        <div className="text-container">
                            <p>I'm a: </p>
                            <input type="text" value="Recruiter" readOnly disabled />
                        </div>
                        <input type="text" placeholder="Email" onChange={e => setEmail(e.target.value)}/>

                        <div className="password-restraint-div">
                            <div className="password-restraint-1">
                                <div>
                                    { !hasEightChars ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' />}
                                    <span className="password-restraint-text">Min 8 characters</span>
                                </div>
                                <div>
                                    { !containNums ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                                    <span className="password-restraint-text">Contains numbers</span>
                                </div>
                            </div>
                            <div className='password-restraint-2'>
                                <div>
                                    { !containUpperCase ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                                    <span className="password-restraint-text">Contains uppercase letters</span>
                                </div>
                                <div>
                                    { !containLowerCase ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                                    <span className="password-restraint-text">Contains lowercase letters</span>
                                </div>
                            </div>
                            <div className='password-restraint-3'>
                                <div>
                                    { !containNonAlphaNumeric ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                                    <span className="password-restraint-text">Contains non-alphanumeric</span>
                                </div>
                            </div>
                        </div>

                        <div className="text-input-signup">
                            <input
                                id="outlined-basic"
                                variant="outlined"
                                label="Password"
                                placeholder="Password"
                                className="text-input"
                                type={hidden === false ? "password" : "text"}
                                value={password}
                                onChange={(e) => handlePasswordRestraintCheck(e)}
                                name="password"
                            />
                            <img
                                className="eyeicon"
                                src={
                                    hidden === false ? EyeOpen : EyeClose
                                }
                                alt="Eye Icon"
                                onClick={(e) => passShowHide(e)}
                            />
                        </div>
                        <p className="password_forgot">
                          <button
                            className="login__forgot-link"
                            onClick={handleSetTruePassModal}>Forgot Password?
                          </button>
                        </p>
                    </div>

                    {
                      isResetBtnDisabled ? (
                        <div className="disabled-signup-btn" value="submit">
                            <p id="disabled-signup-text">Sign Up</p>
                        </div>
                      ) : (
                       <div className="button" value="submit" onClick={registerFunc}>
                            <p>Sign Up</p>
                        </div>
                      )
                    }
                    <div className="btn-text" >
                        <p>Already have an account?</p>
                        <span
                        onClick={() => {
                            setIsLoggedIn(true);
                        }}
                        style={{fontSize: " 1.2rem",cursor: "pointer" , color:"black", fontWeight:"500"}}
                        >
                        Login
                        </span>
                    </div>

                    <p><b>Sign Up With</b></p>
                    <ExternalAuth isLoggedIn={false} />
                </div>
                <img src={Graphic} alt="" className='signup-img' />
            </div>
        </div>
    )
}

export default RecruiterLogin;
import React, { useState } from "react";
import "./styles.css";
import Logo from "../../images/ig-logo.png";
import Search from "../../images/search.svg";
import Filter from "../../images/filter.svg";
import Dashboard from "../../images/dashboard.svg";
import addDashboardLogo from "../../images/addDashboardLogo.svg";
import profileDashboardLogo from "../../images/profileDashboardLogo.svg";
import DownArrow from "../../images/down-arrow.svg";
import MobileMenu from "../../images/mobile-menu.svg";
import PostInternships from "../postInternship/index.js";
import Profile from "../../screens/Profiles/Profile.jsx";

function Navbar({ search ,showSidebar}) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  function profileBtnClicked() {
    setShowProfile(!showProfile);
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="navbar">
        {!showProfile && (
            <div className={isNavExpanded ? "top-bar expanded" : "top-bar"}>
            <div className="left-container">
              <img src={Logo} alt="" className="logo" />
            </div>
            <ul className="nav-elements">
              <li>
                <a href="">Dashboard</a>
              </li>
              <li className="arrow-container" onClick={toggleDropdown}>
                <a href="#">User</a>
                <img src={DownArrow} alt="" />
                {isDropdownOpen && (
                  <ul className="dropdown-content">
                    <li><a href="/login">Logout</a></li>
                  </ul>
                )}
              </li>
            </ul>
            <button
              className="hamburger mobile-menu"
              onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
              <img src={MobileMenu} alt="" className="mobile-menu" />
            </button>
          </div>
        )}
      {!search && showSidebar && (
        <div className={isNavExpanded ? "side-bar expanded" : "side-bar"}>
          <div className="sidebar-item">
            <img src={Dashboard} alt="" className="navbar__img dashimg" />
            <p>Dashboard</p>
          </div>
          <PostInternships src={addDashboardLogo}/>
          <button onClick={profileBtnClicked} className="sidebar-item" >
            <img src={profileDashboardLogo} alt="" className="navbar__img  profileimg" />
            <p className="sidebar-label">Profile</p>
          </button>
        </div>
      )}
      {showProfile && (
            <div className="profile__overlay-div">
              <Profile onExitClick = {profileBtnClicked}/>
            </div>
          )}
    </div>
  );
}

export default Navbar;

import React, {useState, useEffect} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dashboard from './screens/dashboard';
import ApplicantList from "./screens/applicantList";
import RecruiterLogin from './screens/recruiterLogin';
import RecruiterInfo from './screens/recruiterInfo';
import CompanyInfo from './screens/companyInfo';
import RecruiterSearch from './screens/recruiterSearch';
import HowItWorks from './screens/howItWorks';
import HomePage from './screens/HomePage';
import PressPage from './screens/Press/PressPage';
import Contact from './screens/contact';
import ReactGA from "react-ga4";
import Blog from "./screens/blog";
import BlogDetails from "./screens/blogDetails";
import BlogWriter from './screens/blogWriter';


ReactGA.initialize('G-TWC44R4Q7F');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Intern Guys Recruiter GA" });

let theme = createTheme({
  palette: {
    primary: {
      main: '#213546',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
});

function App() {
  const [blogs, setBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try { 
      const response = await fetch(process.env.REACT_APP_GET_BLOGS as string);
      if (response.ok) {
        let data = await response.json(); 
        setBlogs(data);
        setFeaturedBlog(data.filter((blog: any) => blog.featured === true));
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/login" element={<RecruiterLogin/>} />
            <Route path="/aboutRecruiter" element={<RecruiterInfo />} />
            <Route path="/aboutCompany" element={<CompanyInfo />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/search" element={<RecruiterSearch />} />
            <Route path="/internship/:internshipId/applicants" element={<ApplicantList/>} />
            <Route path="/howItWorks" element={<HowItWorks/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/press" element={<PressPage />} />
            <Route path="/blog" element= {<Blog blogs={blogs} featured={featuredBlog}/>}></Route>
          <Route path="/blog-details/:blogId" element={<BlogDetails />} />
         <Route path="/blog-writer" element={<BlogWriter/>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
